<script setup>
const navigation = {
  ceekItems: [
    { name: "Explore", href: "/explore" },
    { name: "Support", href: "/support/" },
    { name: "User Guide", href: "/userguide/" },
    { name: "Creator Dashboard", href: "https://creator.ceek.com/" },
  ],
  communityItems: [
    { name: "CEEK Token", href: "https://www.ceek.io/" },
    { name: "Voting", href: "https://voting.ceek.com" },
    { name: "Terms", href: "/terms/" },
    { name: "Privacy", href: "https://www.ceek.com/privacy/" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/ceekapp/",
      icon: resolveComponent("IconFooterFaceBook"),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/ceek",
      icon: resolveComponent("IconFooterTwitter"),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/ceekvr/",
      icon: resolveComponent("IconFooterInstagram"),
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UCkt5aX3nCQhdREFCeVYD92g",
      icon: resolveComponent("IconFooterYouTube"),
    },
    {
      name: "Telegram",
      href: "https://t.me/ceekmetaverse",
      icon: resolveComponent("IconFooterTelegram"),
    },
    {
      name: "Discord",
      href: "https://discord.com/invite/ceekmetaverse",
      icon: resolveComponent("IconFooterDiscord"),
    },
  ],
};

const text = `${new Date().getFullYear()} CGI Global Panama Inc. All Rights Reserved`;
</script>

<template>
  <footer class="bg-[#EFEFEF] dark:bg-[#1D1D1D]" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto w-full max-w-[1920px] px-4 pt-16 sm:px-10 xl:px-20">
      <div class="flex w-full flex-col justify-between gap-20 sm:flex-row 2xl:gap-32">
        <div class="mx-auto flex w-full max-w-lg lg:max-w-full">
          <NuxtLink
            to="/"
            aria-label="CEEK logo"
            class="hidden size-full max-h-24 max-w-[6rem] cursor-pointer lg:block"
          >
            <img src="/img/NFT_CEEK_logo.webp" class="size-full object-cover" loading="lazy" alt="CEEK Logo footer" />
          </NuxtLink>
          <MoleculeSubscribeForm />
        </div>
        <div class="flex justify-around gap-10 lg:gap-20 2xl:gap-32">
          <div>
            <p class="bg-gradient-blue bg-clip-text text-xl font-semibold uppercase text-transparent">CEEK</p>
            <ul role="list" class="mt-3 space-y-3 whitespace-nowrap text-base">
              <li v-for="item in navigation.ceekItems" :key="item.name">
                <NuxtLink
                  :to="item.href"
                  class="leading-6 text-[#3B5162] hover:text-[#3B5162]/50 dark:text-white dark:hover:text-white/50"
                  >{{ item.name }}</NuxtLink
                >
              </li>
            </ul>
          </div>
          <div>
            <p class="bg-gradient-blue bg-clip-text text-xl font-semibold uppercase text-transparent">Community</p>
            <ul role="list" class="mt-3 space-y-3 text-base">
              <li v-for="item in navigation.communityItems" :key="item.name">
                <NuxtLink
                  :to="item.href"
                  class="leading-6 text-[#3B5162] hover:text-[#3B5162]/50 dark:text-white dark:hover:text-white/50"
                  >{{ item.name }}</NuxtLink
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 border-t border-gray-900/10 bg-white pb-14 dark:bg-black sm:pb-0">
      <div
        class="mx-auto flex w-full max-w-[1920px] flex-col items-center justify-between gap-3 p-6 text-xs text-black dark:text-white sm:flex-row lg:px-8 xl:px-20"
      >
        <span class="text-base">&copy; {{ text }}</span>
        <div class="flex space-x-6">
          <NuxtLink
            v-for="item in navigation.social"
            :key="item.name"
            :href="item.href"
            aria-label="social"
            target="_blank"
            rel="noopener noreferrer"
            class="text-gray-500 transition-none hover:text-gray-400"
          >
            <component :is="item.icon" class="size-7" aria-hidden="true"></component>
          </NuxtLink>
        </div>
      </div>
    </div>
  </footer>
</template>
